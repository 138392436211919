.w-md-editor-toolbar-child {
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 0 0 1px var(--color-border-default), 0 0 0 var(--color-border-default), 0 1px 1px var(--color-border-default);
  background-color: var(--color-canvas-default);
  z-index: 1;
  display: none;
}
.w-md-editor-toolbar-child.active {
  display: block;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar {
  border-bottom: 0;
  padding: 3px;
  border-radius: 3px;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li {
  display: block;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
  width: -webkit-fill-available;
  height: initial;
  box-sizing: border-box;
  padding: 3px 4px 2px 4px;
  margin: 0;
}
